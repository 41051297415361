
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import Sidebar from "@/components/left-sidebar.vue"; // @ is an alias to /src
import RSidebar from "@/components/right-sidebar.vue"; // @ is an alias to /src
import HRMService from "../../../services/hrm-service";
import projectService from "@/services/project-service";
import PopUp from "@/components/PopUp.vue";
import utils from "@/utils/utils";
import EmployeePdfComponent from "@/components/employee-summary-pdf.vue";
import router from "@/router";
import { BvModal } from "bootstrap-vue";
import UserManagementService from "@/services/user-management";
import SeparationVerificationSidebar from "@/components/sidebar-separation-verificaiton.vue"
import LeaveBalancePdf from "@/components/leave-balance-pdf.vue"

@Component({
  components: {
    Header,
    Sidebar,
    RSidebar,
    PopUp,
    EmployeePdfComponent,
    SeparationVerificationSidebar,
    LeaveBalancePdf,
  },
})
export default class Employee extends Vue {
  @Prop({ type: String })
  id!: string;
  edit = true;
  new_emp_id_exist = 0;
  basic_employee: any = {};
  employee: any = {};
  per_employee: any = {};
  errors_employee = [];
  salary: any = {};
  salary_list: any = [];
  passport: any = {};
  iqama: any = {};
  education: any = {};
  designation: any = {};
  department: any = {};
  branch: any = {};
  type: any = {};
  aramco: any = {};
  visa: any = {};
  license: any = {};
  workpermit: any = {};
  contract: any = {};
  item_attendance: any = [];
  item_project: any = [];
  ddl_project: any = [];
  ddl_asset: any = [];
  item_asset: any = [];
  project_obj: any = {};

  edu_obj: any = {};
  item_edu: any = [];
  asset_obj: any = {};

  passport_obj: any = {};
  item_passport: any = [];

  iqama_obj: any = {};
  item_iqama: any = [];

  visa_obj: any = {};
  item_visa: any = [];

  workpermit_obj: any = {};
  item_workpermit: any = [];

  drivinglicense_obj: any = {};
  item_drivinglicense: any = [];

  medicalinsurance_obj: any = {};
  item_medicalinsurance: any = [];

  contract_obj: any = {};
  item_contract: any = [];

  aramco_obj: any = {};
  item_aramco: any = [];

  dependent_obj: any = {};
  item_dependent: any = [];

  leave_obj: any = {};
  item_leave: any = [];
  policy_obj: any = {}
  item_policy: any = []

  doc_obj: any = {};
  item_doc: any = [];
  public document_file: any = null;
  public asset_document_file: any = null;
  public handover_document_file: any = null;

  for_handover = false;
  for_handover_edit = false;
  error: any = "";
  percent: any = [];
  newEmpAccounts: any = {};
  newEmpAssets: any = [];
  selected_accounts: any = {};
  selected_assets: any = [];
  month = 0;
  year = 0;
  new_salary_id_exist = null;
  blood_group = ["A+", "A-", "B+", "B-", "O+", "O-", "AB+", "AB-"];
  expats = ["Saudi", "Non Saudi"];
  nationality = [
    "British",
    "Argentinian",
    "Australian",
    "Bahamian",
    "Belgian",
    "Brazilian",
    "Canadian",
    "Chinese",
    "Colombian",
    "Cuban",
    "Dominican",
    "Ecuadorean",
    "Salvadorean",
    "French",
    "German",
    "Guatemalan",
    "Haitian",
    "Honduran",
    "Indian",
    "Ireland",
    "Israeli",
    "Italian",
    "Japanese",
    "South Korean",
    "Mexican",
    "Dutch",
    "Philippine",
    "Spanish",
    "Swedish",
    "Swiss",
    "Taiwanese",
    "Venezuelan",
    "Vietnamese",
    "Afghan",
    "Albanian",
    "Algerian",
    "Samoan",
    "Andorran",
    "Angolan",
    "Armenian",
    "Austrian",
    "Azerbaijani",
    "Bahraini",
    "Bangladeshi",
    "Barbadian",
    "Belarusian",
    "Belizean",
    "Beninese",
    "Bermudian",
    "Bhutanese",
    "Bolivian",
    "Bosnian",
    "Botswanan",
    "Bulgarian",
    "Burkinese",
    "Burundian",
    "Cambodian",
    "Cameroonian",
    "Cape Verdean",
    "Chadian",
    "Chilean",
    "Congolese",
    "Costa Rican",
    "Croat",
    "Cypriot",
    "Czech",
    "Danish",
    "Djiboutian",
    "Dominican",
    "Egyptian",
    "Eritrean",
    "Estonian",
    "Ethiopian",
    "Fijian",
    "Finnish",
    "Polynesian",
    "Gabonese",
    "Gambian",
    "Georgian",
    "Ghanaian",
    "Greek",
    "Grenadian",
    "Guinean",
    "Guyanese",
    "Hungarian",
    "Icelandic",
    "Indonesian",
    "Iranian",
    "Iraqi",
    "Jamaican",
    "Jordanian",
    "Kazakh",
    "Kenyan",
    "North Korean",
    "Kuwaiti",
    "Latvian",
    "Lebanese",
    "Liberian",
    "Libyan",
    "Lithuanian",
    "LUXEMBOURG",
    "Madagascan",
    "Malawian",
    "Malaysian",
    "Maldivian",
    "Malian",
    "Maltese",
    "Mauritanian",
    "Mauritian",
    "Monacan",
    "Mongolian",
    "Montenegrin",
    "Moroccan",
    "Mozambican",
    "Namibian",
    "Nepalese",
    "New Zealand",
    "Nicaraguan",
    "Nigerien",
    "Nigerian",
    "Norwegian",
    "Omani",
    "Pakistani",
    "Panamanian",
    "Guinean",
    "Paraguayan",
    "Peruvian",
    "Polish",
    "Portuguese",
    "Qatari",
    "Romanian",
    "Rwandan",
    "Saudi Arabian",
    "Senegalese",
    "Serb or Serbian",
    "Sierra Leonian",
    "Singaporean",
    "Slovak",
    "Slovenian",
    "Slomoni",
    "Somali",
    "South African",
    "Sri Lankan",
    "Sudanese",
    "Surinamese",
    "Swazi",
    "Tajik",
    "Thai",
    "Togolese",
    "Trinidadian",
    "Tunisian",
    "Turkish",
    "Turkoman",
    "Tuvaluan",
    "Ugandan",
    "Ukrainian",
    "Emirati",
    "American",
    "Uruguayan",
    "Uzbek",
    "Vanuatuan",
    "Yemeni",
    "Zambian",
  ];

  public salaryfields: any = [
    { key: "id", label: "Sno", visible: true },
    { key: "start_at", visible: true },
    { key: "end_at", visible: true },
    { key: "basic", visible: true },
  ];

  feildsError = "Please fill all feilds";
  fieldType = "";
  public entilementPolicy: any = [];
  public selectedEntitlementPolicies: any = [];
  // For Modal
  modalShow = false;
  modalMessage = "";
  modalType = "";
  closeModal() {
    this.modalShow = false;
    this.modalMessage = "";
    this.modalType = "";
    if (this.new_emp_id_exist != Number(this.id)) {
      router.push("/hrm/employee/employee-list");
    }
  }
  showModal(message: string, type: "success" | "error") {
    this.modalShow = true;
    this.modalMessage = message;
    this.modalType = type;
  }
  // modal end

  create() {
    this.basic_employee["birth_date"] = "2022-01-01";
    this.basic_employee["joined_date"] = "2022-01-01";
    this.basic_employee["employment_effective_date"] = "2022-01-01";
    this.basic_employee["years_of_experience"] = 0;
    this.error = ""
    const error_list = utils.getEmptyKeys(this.basic_employee, ["code",
      "title",
      "first_name",
      "last_name",
      "actual_designation",])
    if (error_list.length > 0) {
      this.fieldType = "employee";
      this.error = utils.generateErrorMessage(error_list);
    }
    else {
      // if (
      //   utils.checkIfKeyIsEmpty(this.basic_employee, [
      //     "code",
      //     "title",
      //     "first_name",
      //     "last_name",
      //     "actual_designation",
      //   ])
      // ) {
      //   this.fieldType = "employee";
      // } else {
      if (this.new_emp_id_exist) {
        this.save_per(false)
      } else {
        HRMService.create_basic_emp(this.basic_employee)
          .then((response) => {
            this.showModal("Employee Created Successfully", "success");
            this.fieldType = "";
            this.$router.push({ path: `/hrm/employee/employee-list` });
          })
          .catch((e) => {
            if (e.response.status === 400) {
              this.showModal(e.response.data, "error");
            }
            else {
              this.showModal("Employee Not Created", "error");
            }
          });
      }
    }
  }

  save_per(sepration: boolean) {
    let check = false;
    if (sepration) {
      this.error = ""
      const error_list = utils.getEmptyKeys(this.per_employee, ["sep_type", "sep_date", "sep_comment"])
      if (error_list.length > 0) {
        this.fieldType = "sep";
        this.error = utils.generateErrorMessage(error_list);
        this.showModal(this.error, "error")
      }
      else {
        // if (
        //   utils.checkIfKeyIsEmpty(this.per_employee, [
        //     "sep_type",
        //     "sep_date",
        //     "sep_comment",
        //   ])
        // ) {
        //   this.fieldType = "sep";
        // } else {
        check = true;
      }
    } else if (!sepration) {
      this.error = ""
      const error_list = utils.getEmptyKeys(this.per_employee, ["nationality", "birth_date", "joined_date", "employment_effective_date",])
      if (error_list.length > 0) {
        this.fieldType = "basic";
        this.error = utils.generateErrorMessage(error_list);
      }
      else {
        // if (
        //   utils.checkIfKeyIsEmpty(this.per_employee, [
        //     "contact_num",
        //     "expats",
        //     "blood_group",
        //     "nationality",
        //     "birth_date",
        //     "joined_date",
        //     "employment_effective_date",
        //     "years_of_experience",
        //     "comments",
        //   ])
        // ) {
        //   this.fieldType = "basic";
        // } else {
        check = true;
      }
    }
    if (check) {
      HRMService.update(this.new_emp_id_exist, this.per_employee)
        .then((response) => {
          this.showModal("Employee Updated Successfully", "success");
          this.fieldType = "";
          check = false;
        })
        .catch((e) => {
          if (e.response.status === 400) {
            this.showModal(e.response.data.message, "error");
          }
          else {
            this.showModal("Employee Not Updated", "error");
          }
        });
    }
  }
  public selected_emp_id = '';
  getLoginEmployee() {
    UserManagementService.get_Login_User()
      .then((res) => {
        this.selected_emp_id = res.data.employee;
        if (this.selected_emp_id) {
          this.new_emp_id_exist = Number(this.selected_emp_id);
          this.get_all(this.selected_emp_id);
          this.getMapComponent();
        }
      })
  }

  mounted() {
    if (this.id) {
      this.new_emp_id_exist = Number(this.id);
      this.get_all(this.id);
      this.getMapComponent();
      this.getLeaveTypes();
      this.getEmployeeLeave(this.id, "not-call")
    }
    else if (this.id === '') {
      this.new_emp_id_exist = 0;
    }
    else if (!this.id) {
      this.getLoginEmployee()
    }
  }

  get_all(id: string) {
    this.retrieve(id);
    let dateObj = new Date();
    this.month = dateObj.getUTCMonth() + 1;
    this.year = dateObj.getUTCFullYear();

    let obj = { year: this.year, month: this.month, employee_id: id };
    this.get_attendance(obj);
    this.getproject_ddl();
    this.getasset_ddl();
    this.get_project_emp(id);
    this.get_asset_emp(id);
    this.get_edu_emp(id);
    this.get_passport_emp(id);
    this.get_iqama_emp(id);
    this.get_visa_emp(id);
    this.get_workpermit_emp(id);
    this.get_drivinglicense_emp(id);
    this.get_contract_emp(id);
    this.get_aramco_emp(id);
    this.get_medicalinsurance_emp(id);
    this.get_doc_emp(id);
    this.get_dependent_emp(id);
    this.get_leave_emp(id);
    this.getEntitlementPolicy(id);
    this.getEmployeeLeaveEntitlment(id);
  }

  retrieve(id: string) {
    HRMService.get_employee(id)
      .then((response) => {
        let data = response.data;
        this.basic_employee = data.employee;
        this.per_employee = data.employee;
        this.calculateFields(this.per_employee, 'object', 'Basic')
        // let lastCreatedAt = 0;
        // let _temp_salary = data.salary;
        // for (const salaryObject of _temp_salary) {
        //   const createdAtTimestamp = new Date(salaryObject.created_at).getTime();

        //   if (createdAtTimestamp > lastCreatedAt) {
        //     lastCreatedAt = createdAtTimestamp;
        //     _temp_salary = salaryObject;
        //   }
        // }
        this.salary_list = data.salary;
        this.passport = data.passport;
        this.iqama = data.iqama;
        this.education = data.education;
        this.designation = data.designation;
        this.department = data.department;
        this.branch = data.branch;
        this.type = data.type;
        this.aramco = data.aramco;
        this.visa = data.visa;
        this.license = data.license;
        this.workpermit = data.workpermit;
        this.contract = data.contract;
      })
      .catch((e) => {
        console.log(e);
      });
  }
  fields: any = []
  format(data: any) {
    return data.join(', ');
  }
  calculateFields(item: any, type: any, name: string) {
    const totalFields = type === 'array' && item[0]
      ? Object.keys(item[0]).length
      : type === 'object' && item
        ? Object.keys(item).length
        : 0;

    const totalFilledFields = type === 'array' && item[0]
      ? Object.values(item[0])
        .filter(value => value !== null && value !== 'Null')
        .length
      : type === 'object' && item
        ? Object.values(item)
          .filter(value => value !== null && value !== 'Null')
          .length
        : 0;

    const nullFields = type === 'array' && item[0]
      ? Object.values(item[0]).filter(value => value == null).length
      : type === 'object' && item
        ? Object.values(item)
          .filter(value => value == null)
          .length
        : 0;

    // Add the count of null fields to totalFilledFields
    const totalFilledFieldsWithNull = totalFilledFields + nullFields;

    const totalValue = totalFields === 0 ? 0 : (totalFilledFieldsWithNull / totalFields) * 100;
    if (totalValue !== 100) {
      this.fields.push(name)
    }

    this.percent.push(totalValue)
    if (this.percent.length === 12) {
      this.percentage();
    }
  }
  seriesValue: any = []
  percentage() {
    const sum = this.percent.reduce((acc: any, value: any) => acc + value, 0);
    this.seriesValue = (sum / this.percent.length);
  }
  get_attendance(params: any) {
    HRMService.get_attendance_sheet_by(params)
      .then((response) => {
        this.item_attendance = response.data.result;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  get_project_emp(params: any) {
    HRMService.get_project_by_emp(params)
      .then((response) => {
        this.item_project = response.data.result;
        this.calculateFields(this.item_project, 'array', 'Work Location')
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getproject_ddl() {
    projectService
      .get_list()
      .then((res) => {
        this.ddl_project = res.data;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  create_project(data: any) {
    this.error = ""
    const error_list = utils.getEmptyKeys(data, ["option_to", "start_date"])
    if (error_list.length > 0) {
      this.fieldType = "worklocation";
      this.error = utils.generateErrorMessage(error_list);
    }
    else {
      // if (
      //   utils.checkIfKeyIsEmpty(data, [
      //     // "option_from",
      //     "option_to",
      //     "start_date",
      //     // "end_date",
      //     // "comments",
      //   ])
      // ) {
      //   this.fieldType = "worklocation";
      // } else {
      if (data.id) {

        let selected_to = this.ddl_project.find(
          (x: any) => x.id == data.option_to
        );
        let obj = {
          employee: this.id,
          project_to: selected_to.id,
          project_title_to: selected_to.title,
          comments: data.comments,
          start_date: data.start_date,
          end_date: data.end_date,
          employee_title: this.basic_employee.title,
          employee_code: this.basic_employee.code,
        };

        projectService
          .update_project_emp(data.id, obj)
          .then((res) => {
            this.get_project_emp(this.id);
            this.project_obj = {};
            this.showModal("Work Location Updated, Successfully", "success");
            this.fieldType = "";
          })
          .catch((e) => {
            console.log(e);
            this.showModal("Work Location Not Updated", "error");
          });
      } else {

        let selected_to = this.ddl_project.find(
          (x: any) => x.id == data.option_to
        );
        let obj = {
          employee: this.id,
          project_to: selected_to.id,
          project_title_to: selected_to.title,
          comments: data.comments,
          start_date: data.start_date,
          end_date: data.end_date,
          employee_title: this.basic_employee.title,
          employee_code: this.basic_employee.code,
        };

        projectService
          .create_project_emp(obj)
          .then((res) => {
            this.get_project_emp(this.id);
            this.project_obj = {};
            this.showModal("Work Location Created, Successfully", "success");
            this.fieldType = "";
          })
          .catch((e) => {
            // console.log(e);
            this.showModal("Work Location Not Created", "error");
          });
      }
    }
  }

  get_asset_emp(params: any) {
    HRMService.get_asset_by_emp(params)
      .then((response) => {
        this.item_asset = response.data.result;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getasset_ddl() {
    HRMService.getAllassest()
      .then((res) => {
        this.ddl_asset = res.data;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  create_asset(data: any) {
    let selected = this.ddl_asset.find((x: any) => x.id == data.option);
    if (selected == undefined) {
      this.fieldType = "asset";
      this.error = "Please Select Assets"
      return;
    }
    let obj: any = {
      employee: this.id,
      asset: selected.id,
      asset_title: selected.title,
      comments: data.comments,
      assigned_date: data.assigned_date,
    };

    // if (
    //   utils.checkIfKeyIsEmpty(obj, [
    //     "asset",
    //     "asset_title",
    //     "comments",
    //     "assigned_date",
    //   ])
    // ) {
    //   this.fieldType = "asset";
    // } else {
    if (data.id === null || data.id === undefined || !data.id) {
      this.error = ""
      const error_list = utils.getEmptyKeys(this.asset_obj, ["assigned_date"])
      if (error_list.length > 0 || this.asset_document_file === null) {
        this.fieldType = "asset";
        this.error = utils.generateErrorMessage(error_list.concat(this.asset_document_file === null ? 'Asset Document' : ''));
      }
      else {
        const isValidFile = this.validateFile(this.asset_document_file);
        if (!isValidFile) {
          this.showModal("Invalid file. Please upload a valid file.", "error")
        } else {
          HRMService.create_emp_asset(this.asset_document_file, obj)
            .then((res) => {
              this.get_asset_emp(this.id);
              this.asset_obj = {};
              this.asset_document_file = null;
              this.handover_document_file = null;
              this.showModal("Assets Created Successfully", "success");
              this.fieldType = "";
            })
            .catch((e) => {
              console.log(e);
              this.showModal("Assets Not Created", "error");
            });
        }
      }
    }
    else if (data.id != null || data.id !== undefined || data.id) {
      if (this.for_handover) {
        this.error = ""
        const error_list = utils.getEmptyKeys(this.asset_obj, ["handover_to__title"])
        if ((error_list.length > 0 || this.handover_document_file === null) && this.for_handover_edit === false) {
          console.log(this.for_handover_edit)
          this.fieldType = "asset";
          this.error = utils.generateErrorMessage(error_list.concat(this.handover_document_file === null ? 'Handover Document' : ''));
        } else if (this.for_handover_edit === true && error_list.length > 0) {
          this.fieldType = "asset";
          console.log(this.for_handover_edit)
          this.error = utils.generateErrorMessage(error_list);
        } else {
          obj['handover_date'] = this.asset_obj.handover_date
          obj['handover_to_id'] = this.asset_obj.handover_to_id
        }
      }
      const isValidFile = this.validateFile(this.handover_document_file);
      if (!isValidFile) {
        this.showModal("Invalid file. Please upload a valid file.", "error")
      } else {
        HRMService.update_emp_asset(this.for_handover, data.id, obj, this.asset_document_file, this.handover_document_file)
          .then((res) => {
            this.get_asset_emp(this.id);
            this.asset_obj = {};
            this.asset_document_file = null;
            this.handover_document_file = null;
            this.showModal("Assets Updated Successfully", "success");
            this.fieldType = "";
            this.for_handover = false
          })
          .catch((e) => {
            console.log(e);
            this.showModal("Assets Not Updated", "error");
          });
      }
    }
  }


  get_edu_emp(params: any) {
    HRMService.get_edu_by_emp(params)
      .then((response) => {
        this.item_edu = response.data.result;
        this.calculateFields(this.item_edu, 'array', 'Education')
      })
      .catch((e) => {
        console.log(e);
      });
  }

  create_edu(data: any) {
    data["employee"] = this.new_emp_id_exist;
    data["employee_title"] = this.basic_employee.title;

    // if (
    //   utils.checkIfKeyIsEmpty(this.edu_obj, [
    //     "education",
    //     "edu_type",
    //     "year_of_completion",
    //     "comments",
    //   ])
    // ) {
    //   this.fieldType = "education";
    // } else {
    if (data.id) {
      HRMService.update_edu(data.id, data)
        .then((res) => {
          this.get_edu_emp(this.new_emp_id_exist);
          this.edu_obj = {};
          this.fieldType = "";
          //
          this.showModal("Education Updated Successfully", "success");
        })
        .catch((e) => {
          console.log(e);
          this.showModal("Education Not Updated", "error");
        });
    } else {
      HRMService.create_edu(data)
        .then((res) => {
          this.get_edu_emp(this.new_emp_id_exist);
          this.edu_obj = {};
          this.fieldType = "";

          this.showModal("Education Created Successfully", "success");
        })
        .catch((e) => {
          console.log(e);
          this.showModal("Education Not Created", "error");
        });
    }
  }
  // }
  // here all edits ////////////////////////
  edit_edu(data: any) {
    this.edu_obj = data;
  }
  edit_passport(data: any) {
    this.passport_obj = data;
  }
  edit_iqama(data: any) {
    this.iqama_obj = data;
  }
  edit_aramco(data: any) {
    this.aramco_obj = data;
  }
  edit_visa(data: any) {
    this.visa_obj = data;
  }
  edit_workpermit(data: any) {
    this.workpermit_obj = data;
  }
  edit_drivingLicense(data: any) {
    this.drivinglicense_obj = data;
  }
  edit_workLocation(data: any) {
    // let selected_from = this.ddl_project.find(
    //   (x: any) => x.id == data.project_from_id
    // );
    let selected_to = this.ddl_project.find(
      (x: any) => x.id == data.project_to_id
    );
    // (data["option_from"] = selected_from.id),
    (data["option_to"] = selected_to.id),
      (this.project_obj = data);
  }
  edit_medicalInsurance(data: any) {
    this.medicalinsurance_obj = data;
  }
  edit_assets(data: any) {
    let selected = this.ddl_asset.find((x: any) => x.id == data.asset_id);
    data["option"] = selected.id;
    this.asset_obj = data;
    this.for_handover = false;
    this.asset_obj.handover_date = "";
    this.asset_obj.handover_to__title = "";
    this.handover_document_file = null



  }
  handle_handover(data: any) {
    let selected = this.ddl_asset.find((x: any) => x.id == data.asset_id);
    data["option"] = selected.id;
    this.asset_obj = data;
    this.for_handover = true;
    const today = new Date();
    const date = today.toISOString().split('T')[0];
    this.asset_obj.handover_date = date;
    this.for_handover_edit = false;
    if (data.handover_to__title !== null) {
      this.for_handover_edit = true;
    }
  }
  edit_dependent(data: any) {
    this.dependent_obj = data;
  }

  edit_leave(data: any) {
    if (data.manage_leave_status === null) {
      this.leave_obj = data;
      // this.leave_obj.leave_type = data.leave_type__title;       //to show the selected option in Leave Type
      this.getNumberOfLeaveDays(data);       //to show the total number of leave days
    } else {
      this.showModal(`Once leave is ${data.manage_leave_status} you cannot edit it.`, "error")
    }
  }

  edit_entitlment_policy(data: any) {
    this.policy_obj = data
    // console.log(this.policy_obj)
    // console.log()
    // const targetEntitlementPolicy = this.policy_obj.entitlementpolicy;

    // console.log(targetEntitlementPolicy)
    // console.log(this.entilementPolicy)

    // Filter this.entilementPolicy based on the extracted entitlementpolicy
    // const selectedtitle = this.entilementPolicy.filter((policy: any) => policy.id === targetEntitlementPolicy);
    // this.selectedEntitlementPolicies = selectedtitle[0].title
    // console.log(this.selectedEntitlementPolicies)
    // console.log(this.selectedEntitlementPolicies)
    // const filteredPolicies = this.entilementPolicy.filter((policy: any) => policy.entitlementpolicy === this.policy_obj.entitlementpolicy);

    // console.log(filteredPolicies);


  }


  edit_doc(data: any) { }
  ////////////////////////////////////////////////////////////////////////

  get_passport_emp(params: any) {
    HRMService.get_passport_by_emp(params)
      .then((response) => {
        this.item_passport = response.data.result;
        this.calculateFields(this.item_passport, 'array', 'Passport')
      })
      .catch((e) => {
        console.log(e);
      });
  }

  create_passport(data: any) {
    data["employee"] = this.new_emp_id_exist;
    data["employee_title"] = this.basic_employee.title;
    this.error = ""
    const error_list = utils.getEmptyKeys(data, ["passport_num", "passport_issue_place", "issue_date", "expiry_date"])
    if (error_list.length > 0) {
      this.fieldType = "passport";
      this.error = utils.generateErrorMessage(error_list);
    }
    else {
      // if (
      //   utils.checkIfKeyIsEmpty(data, [
      //     "passport_num",
      //     "passport_issue_place",
      //     "issue_date",
      //     "expiry_date",
      //     "comments",
      //   ])
      // ) {
      //   this.fieldType = "passport";
      // } else {
      if (data.id) {
        HRMService.update_passport(data.id, data)
          .then((res) => {
            this.get_passport_emp(this.new_emp_id_exist);
            this.passport_obj = {};
            this.fieldType = "";

            this.showModal("Passport Updated Successfully", "success");
          })
          .catch((e) => {
            console.log(e);
            this.showModal("Passport Not Updated", "error");
          });
      } else {
        HRMService.create_passport(data)
          .then((res) => {
            this.get_passport_emp(this.new_emp_id_exist);
            this.passport_obj = {};
            this.fieldType = "";

            this.showModal("Passport Created Successfully", "success");
          })
          .catch((e) => {
            console.log(e);
            this.showModal("Passport Not Created", "error");
          });
      }
    }
  }

  get_iqama_emp(params: any) {
    HRMService.get_iqama_by_emp(params)
      .then((response) => {
        this.item_iqama = response.data.result;
        this.calculateFields(this.item_iqama, 'array', 'Iqama')
      })
      .catch((e) => {
        console.log(e);
      });
  }

  create_iqama(data: any) {
    data["employee"] = this.new_emp_id_exist;
    data["employee_title"] = this.basic_employee.title;
    this.error = ""
    const error_list = utils.getEmptyKeys(data, ["iqama_num", "issue_date", "expiry_date"])
    if (error_list.length > 0) {
      this.fieldType = "iqama";
      this.error = utils.generateErrorMessage(error_list);
    }
    else {
      // if (
      //   utils.checkIfKeyIsEmpty(data, [
      //     "iqama_num",
      //     "issue_date",
      //     "expiry_date",
      //     "comments",
      //   ])
      // ) {
      //   this.fieldType = "iqama";
      // } else {
      if (data.id) {
        HRMService.update_iqama(data.id, data)
          .then((res) => {
            this.get_iqama_emp(this.new_emp_id_exist);
            this.iqama_obj = {};
            this.showModal("Iqama Updated Successfully", "success");
            this.fieldType = "";
          })
          .catch((e) => {
            console.log(e);
            this.showModal("Iqama Not Updated", "error");
          });
      } else {
        HRMService.create_iqama(data)
          .then((res) => {
            this.get_iqama_emp(this.new_emp_id_exist);
            this.iqama_obj = {};
            this.showModal("Iqama Created Successfully", "success");
            this.fieldType = "";
          })
          .catch((e) => {
            console.log(e);
            this.showModal("Iqama Not Created", "error");
          });
      }
    }
  }

  get_visa_emp(params: any) {
    HRMService.get_visa_by_emp(params)
      .then((response) => {
        this.item_visa = response.data.result;
        this.calculateFields(this.item_visa, 'array', 'Visa')
      })
      .catch((e) => {
        console.log(e);
      });
  }

  create_visa(data: any) {
    data["employee"] = this.new_emp_id_exist;
    data["employee_title"] = this.basic_employee.title;
    data["employee_title"] = this.basic_employee.title;
    this.error = ""
    const error_list = utils.getEmptyKeys(data, ["visa_num", "visa_type", "issue_date", "expiry_date"])
    if (error_list.length > 0) {
      this.fieldType = "visa";
      this.error = utils.generateErrorMessage(error_list);
    }
    else {
      // if (
      //   utils.checkIfKeyIsEmpty(data, [
      //     "visa_type",
      //     "issue_date",
      //     "expiry_date",
      //     "comments",
      //   ])
      // ) {
      //   this.fieldType = "visa";
      // } else {
      if (data.id) {
        HRMService.update_visa(data.id, data)
          .then((res) => {
            this.get_visa_emp(this.new_emp_id_exist);
            this.visa_obj = {};
            this.showModal("Visa Updated Successfully", "success");
            this.fieldType = "";
          })
          .catch((e) => {
            console.log(e);
            this.showModal("Visa Not Updated", "error");
          });
      } else {
        HRMService.create_visa(data)
          .then((res) => {
            this.get_visa_emp(this.new_emp_id_exist);
            this.visa_obj = {};
            this.showModal("Visa Created Successfully", "success");
            this.fieldType = "";
          })
          .catch((e) => {
            console.log(e);
            this.showModal("Visa Not Created", "error");
          });
      }
    }
  }

  get_workpermit_emp(params: any) {
    HRMService.get_workpermit_by_emp(params)
      .then((response) => {
        this.item_workpermit = response.data.result;
        this.calculateFields(this.item_workpermit, 'array', 'Work Permit')
      })
      .catch((e) => {
        console.log(e);
      });
  }

  create_workpermit(data: any) {
    data["employee"] = this.new_emp_id_exist;
    data["employee_title"] = this.basic_employee.title;
    this.error = ""
    const error_list = utils.getEmptyKeys(data, ["issue_date", "expiry_date"])
    if (error_list.length > 0) {
      this.fieldType = "workpermit";
      this.error = utils.generateErrorMessage(error_list);
    }
    else {
      // if (
      //   utils.checkIfKeyIsEmpty(data, ["issue_date", "expiry_date", "comments"])
      // ) {
      //   this.fieldType = "workpermit";
      // } else {
      if (data.id) {
        HRMService.update_workpermit(data.id, data)
          .then((res) => {
            this.get_workpermit_emp(this.new_emp_id_exist);
            this.workpermit_obj = {};
            this.fieldType = "";
            this.showModal("Work Permit Updated Successfully", "success");
          })
          .catch((e) => {
            console.log(e);
            this.showModal("Work Permit Not Updated", "error");
          });
      } else {
        HRMService.create_workpermit(data)
          .then((res) => {
            this.get_workpermit_emp(this.new_emp_id_exist);
            this.workpermit_obj = {};
            this.fieldType = "";
            this.showModal("Work Permit Created Successfully", "success");
          })
          .catch((e) => {
            console.log(e);
            this.showModal("Work Permit Not Created", "error");
          });
      }
    }
  }

  get_drivinglicense_emp(params: any) {
    HRMService.get_drivinglicense_by_emp(params)
      .then((response) => {
        this.item_drivinglicense = response.data.result;
        this.calculateFields(this.item_drivinglicense, 'array', 'Driving License')
      })
      .catch((e) => {
        console.log(e);
      });
  }

  create_drivinglicense(data: any) {
    data["employee"] = this.new_emp_id_exist;
    data["employee_title"] = this.basic_employee.title;
    data["employee_title"] = this.basic_employee.title;
    this.error = ""
    const error_list = utils.getEmptyKeys(data, ["license_num", "issue_date", "expiry_date"])
    if (error_list.length > 0) {
      this.fieldType = "driving";
      this.error = utils.generateErrorMessage(error_list);
    }
    else {
      // if (
      //   utils.checkIfKeyIsEmpty(data, [
      //     "license_num",
      //     "issue_date",
      //     "expiry_date",
      //     "comments",
      //   ])
      // ) {
      //   this.fieldType = "driving";
      // } else {
      if (data.id) {
        HRMService.update_drivinglicense(data.id, data)
          .then((res) => {
            this.get_drivinglicense_emp(this.new_emp_id_exist);
            this.drivinglicense_obj = {};
            this.showModal("Driving License Updated Successfully", "success");
            this.fieldType = "";
          })
          .catch((e) => {
            console.log(e);
            this.showModal("Driving License Not Updated", "error");
          });
      } else {
        HRMService.create_drivinglicense(data)
          .then((res) => {
            this.get_drivinglicense_emp(this.new_emp_id_exist);
            this.drivinglicense_obj = {};
            this.showModal("Driving License Created Successfully", "success");
            this.fieldType = "";
          })
          .catch((e) => {
            console.log(e);
            this.showModal("Driving License Not Created", "error");
          });
      }
    }
  }

  get_medicalinsurance_emp(params: any) {
    HRMService.get_medicalinsurance_by_emp(params)
      .then((response) => {
        this.item_medicalinsurance = response.data.result;
        this.calculateFields(this.item_medicalinsurance, 'array', 'Medical Insurance')

      })
      .catch((e) => {
        console.log(e);
      });
  }

  create_medicalinsurance(data: any) {
    data["employee"] = this.new_emp_id_exist;
    data["employee_title"] = this.basic_employee.title;
    this.error = ""
    const error_list = utils.getEmptyKeys(data, ["insurance_name", "insurance_policy", "expiry_date"])
    if (error_list.length > 0) {
      this.fieldType = "medical";
      this.error = utils.generateErrorMessage(error_list);
    }
    else {
      // if (
      //   utils.checkIfKeyIsEmpty(data, [
      //     "insurance_name",
      //     "insurance_policy",
      //     "issue_date",
      //     "expiry_date",
      //     "comments",
      //   ])
      // ) {
      //   this.fieldType = "medical";
      // } else {
      if (data.id) {
        HRMService.update_medicalinsurance(data.id, data)
          .then((res) => {
            this.get_medicalinsurance_emp(this.new_emp_id_exist);
            this.medicalinsurance_obj = {};

            this.showModal("Medical Insurance Updated Successfully", "success");
            this.fieldType = "";
          })
          .catch((e) => {
            console.log(e);
            this.showModal("Medical Insurance Not Updated", "error");
          });
      } else {
        HRMService.create_medicalinsurance(data)
          .then((res) => {
            this.get_medicalinsurance_emp(this.new_emp_id_exist);
            this.medicalinsurance_obj = {};

            this.showModal("Medical Insurance Created Successfully", "success");
            this.fieldType = "";
          })
          .catch((e) => {
            console.log(e);
            this.showModal("Medical Insurance Not Created", "error");
          });
      }
    }
  }

  get_contract_emp(params: any) {
    HRMService.get_contract_by_emp(params)
      .then((response) => {
        this.item_contract = response.data.result;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  create_contract(data: any) {
    data["employee"] = this.new_emp_id_exist;
    HRMService.create_contract(data)
      .then((res) => {
        this.get_contract_emp(this.new_emp_id_exist);
        this.contract_obj = {};
      })
      .catch((e) => {
        console.log(e);
      });
  }

  get_aramco_emp(params: any) {
    HRMService.get_aramco_by_emp(params)
      .then((response) => {
        this.item_aramco = response.data.result;
        this.calculateFields(this.item_aramco, 'array', 'Project-ID')
      })
      .catch((e) => {
        console.log(e);
      });
  }
  create_aramco(data: any) {
    data["employee"] = this.new_emp_id_exist;
    data["employee_title"] = this.basic_employee.title;
    const keyNameMapping = {
      aramco_num: 'Id',
      comments: 'Project Name',
      issue_date: 'Issue Date',
      expiry_date: 'Expiry Date'
    };
    this.error = "";

    const error_list = utils.getEmptyKeys(data, ["aramco_num", "comments", "issue_date", "expiry_date"]);
    if (error_list.length > 0) {
      this.fieldType = "aramco";
      this.error = utils.generateErrorMessageFields(error_list, keyNameMapping);
    } else {
      if (
        data.issue_date &&
        data.expiry_date &&
        this.item_aramco.length > 0
      ) {
        const maxPreviousExpiryDate = this.item_aramco.reduce((maxDate: any, project: any) => {
          if (project.id !== data.id) {
            const projectExpiryDate = new Date(project.expiry_date);
            return projectExpiryDate > maxDate ? projectExpiryDate : maxDate;
          }
          return maxDate;
        }, new Date(0));

        const newIssueDate = new Date(data.issue_date);
        const newExpiryDate = new Date(data.expiry_date);
        if (newIssueDate <= maxPreviousExpiryDate) {
          this.fieldType = "aramco";
          this.error = "Issue Date of the project must be greater than the previous project's expiry date.";
          return;
        }
        if (newExpiryDate <= maxPreviousExpiryDate) {
          this.fieldType = "aramco";
          this.error = "Expiry Date of the project must be greater than the previous project's expiry date.";
          return;
        }

        if (newIssueDate > newExpiryDate) {
          this.fieldType = "aramco";
          this.error = "Issue Date of the project cannot be greater than the Expiry Date.";
          return;
        }
      }

      if (data.id) {
        HRMService.update_aramco(data.id, data)
          .then((res) => {
            this.get_aramco_emp(this.new_emp_id_exist);
            this.aramco_obj = {};
            this.fieldType = "";
            this.showModal("Project Id Updated Successfully", "success");
          })
          .catch((e) => {
            console.log(e);
            this.showModal("Project Id Not Updated", "error");
          });
      } else {
        HRMService.create_aramco(data)
          .then((res) => {
            this.get_aramco_emp(this.new_emp_id_exist);
            this.aramco_obj = {};
            this.fieldType = "";
            this.showModal("Project Id Created Successfully", "success");
          })
          .catch((e) => {
            console.log(e);
            this.showModal("Project Id Not Created", "error");
          });
      }
    }
  }

  get_doc_emp(params: any) {
    HRMService.get_doc_by_emp(params)
      .then((response) => {
        this.item_doc = response.data.result;
        this.calculateFields(this.item_doc, 'array', 'Document')
        if (this.item_doc.length > 0) {
          this.item_doc.map(function (obj: any) {
            obj.filevalue = "http://localhost:8000/media/" + obj.document;
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  create_doc(data: any) {
    data["employee"] = this.new_emp_id_exist;
    this.error = ""
    const error_list = utils.getEmptyKeys(data, ["name", "type"])
    if (error_list.length > 0 || this.document_file === null) {
      this.fieldType = "doc";
      this.error = utils.generateErrorMessage(error_list.concat(this.document_file === null ? 'File' : ''));
    }
    else {
      const isValidFile = this.validateFile(this.document_file);
      if (!isValidFile) {
        this.showModal("Invalid file. Please upload a valid file.", "error")
      } else {
        // if (utils.checkIfKeyIsEmpty(data, ["name", "type", "comments"])) {
        //   this.fieldType = "doc";
        // } else {
        HRMService.create_doc(this.document_file, data)
          .then((res) => {
            this.get_doc_emp(this.new_emp_id_exist);
            this.doc_obj = {};
            this.showModal("Document Created Successfully", "success");
            this.fieldType = "";
            this.document_file = null
          })
          .catch((e) => {
            console.log(e);
            this.showModal("Document Not Created", "error");
          });
      }
    }
  }

  get_dependent_emp(params: any) {
    HRMService.get_dependent_by_emp(params)
      .then((response) => {
        this.item_dependent = response.data.result;
        this.calculateFields(this.item_dependent, 'array', 'Dependent')
      })
      .catch((e) => {
        console.log(e);
      });
  }

  save_dependent(data: any) {
    data["employee"] = this.new_emp_id_exist;
    this.error = ""
    const error_list = utils.getEmptyKeys(data, ["title", "relation", "contact"])
    if (error_list.length > 0) {
      this.fieldType = "dependent";
      this.error = utils.generateErrorMessage(error_list);
    }
    else {
      // if (utils.checkIfKeyIsEmpty(data, ["title", "relation", "contact"])) {
      //   this.fieldType = "dependent";
      // } else {
      if (data.id) {
        HRMService.update_dependent(data.id, data)
          .then((res) => {
            this.get_dependent_emp(this.new_emp_id_exist);
            this.dependent_obj = {};
            this.fieldType = "";

            this.showModal("Dependent Updated Successfully", "success");
          })
          .catch((e) => {
            console.log(e);
            this.showModal("Dependent Not Updated", "error");
          });
      } else {
        HRMService.create_dependent(data)
          .then((res) => {
            this.get_dependent_emp(this.new_emp_id_exist);
            this.dependent_obj = {};
            this.fieldType = "";

            this.showModal("Dependent Created Successfully", "success");
          })
          .catch((e) => {
            console.log(e);
            this.showModal("Dependent Not Created", "error");
          });
      }
    }
  }

  get_leave_emp(params: any) {
    HRMService.get_employee_leave_by_emp(params)
      .then((response) => {
        this.item_leave = response.data.result;
      })
      .catch((e) => {
        console.log(e);
      });
  }
  checkValidForLeave(data: any, update: boolean): boolean {
    // Convert the date strings to Date objects
    let start_date: any = new Date(data.start_date);
    let end_date: any = new Date(data.end_date);

    // Calculate the difference in milliseconds
    var time_difference = end_date - start_date;

    // Calculate the number of days by dividing the time difference by the number of milliseconds in a day
    var num_days = time_difference / (1000 * 60 * 60 * 24);

    let closing_balance = 0
    let availed_balance = 0
    let type_of_leave = 0

    this.leave_balance.map((value: any) => {
      if (value.title === data.leave_type__title) {
        closing_balance = value.closing_balance
        availed_balance = value.availed_balance
        type_of_leave = data.leave_type__title
      }
    })
    let status = false
    if (!update) {

      if (num_days <= closing_balance) {
        this.leave_obj['balances'] = num_days
        status = true
      }
      else {
        this.showModal(`You are requesting ${num_days} days of leave, but your available leave balance is only ${closing_balance} for Leave Type ${type_of_leave}.`, "error");
        status = false
      }

    }
    else if (update) {

      if (num_days <= closing_balance + availed_balance) {
        this.leave_obj['balances'] = num_days
        status = true
      }
      else {
        this.showModal(`You are requesting ${num_days} days of leave, but your available leave balance is only ${closing_balance} for Leave Type ${type_of_leave}.`, "error");
        status = false
      }

    }
    return status
  }

  leave_days = 0
  getNumberOfLeaveDays(data: any) {


    if (data.start_date === undefined || data.end_date === undefined) return
    let start_date: any = new Date(data.start_date);
    let end_date: any = new Date(data.end_date);
    // Calculate the difference in milliseconds
    var time_difference = end_date - start_date;

    // Calculate the number of days by dividing the time difference by the number of milliseconds in a day

    this.leave_days = time_difference / (1000 * 60 * 60 * 24);
    if (isNaN(this.leave_days)) {
      this.leave_days = 0
    }
  }

  save_leave(data: any) {
    data["employee"] = this.new_emp_id_exist;
    const selectedLeaveType = this.leave_opt.result
      .filter((item: any) => item.leave_type_id === data.leave_type)
      .map((item: any) => item.leave_type)[0];
    data['leave_type__title'] = selectedLeaveType

    this.error = ""
    const error_list = utils.getEmptyKeys(data, ["end_date", "leave_type", "reason"])
    if (error_list.length > 0) {
      this.fieldType = "leave";
      this.error = utils.generateErrorMessage(error_list);
    }
    else {
      // if (
      //   utils.checkIfKeyIsEmpty(data, [
      //     "start_date",
      //     "end_date",
      //     "leave_type_title",
      //     "reason",
      //   ])
      // ) {
      //   this.fieldType = "leave";
      // } else {
      data['employee_title'] = this.basic_employee.title
      data['employee_code'] = this.basic_employee.code
      if (data.id) {
        if (this.checkValidForLeave(data, true)) {

          HRMService.update_employee_leave(data.id, data)
            .then((res) => {
              this.get_leave_emp(this.new_emp_id_exist);
              this.leave_obj = {};
              this.leave_days = 0;
              this.showModal("Leave Updated Successfully", "success");
              this.fieldType = "";
            })
            .catch((e) => {
              console.log(e);
              this.showModal("Leave Not Updated", "error");
            });
        }
      } else {
        if (this.checkValidForLeave(data, false)) {

          HRMService.create_employee_leave(data)
            .then((res) => {

              this.get_leave_emp(this.new_emp_id_exist);
              this.leave_obj = {};
              this.leave_days = 0;
              this.showModal("Leave Created Successfully", "success");
              this.fieldType = "";
            })
            .catch((e) => {
              console.log(e);
              this.showModal("Leave Not Created", "error");
            });
        }
      }
    }
  }
  create_salary() {
    this.salary = {};
    this.new_salary_id_exist = null;
  }
  map_fields: any = {};
  getMapComponent() {
    HRMService.getPayrollConfig().then((res) => {
      this.map_fields = res.data[0].fields_mapping;
      this.MapTable();
    });
  }
  isMonthlySeriesValid(key: any) {
    const fieldData = this.map_fields[key];
    return (
      (key.startsWith("monthly_addition") &&
        (fieldData.status === true || fieldData.status === "true")) ||
      (key.startsWith("monthly_deduction") &&
        (fieldData.status === true || fieldData.status === "true"))
    );
  }
  computeSalary() {
    const keys = Object.keys(this.salary);
    let temp_add = 0;
    let temp_ded = 0;

    keys.forEach((key) => {
      if (key !== "total") {
        let temp_val = parseFloat(this.salary[key] || 0);

        if (Number.isNaN(temp_val)) {
          temp_val = 0;
        }

        if (key.startsWith("monthly_addition") || key.startsWith("basic")) {
          this.salary[key] = temp_val; // Convert and update the value in the dictionary
          temp_add += temp_val;
        } else if (key.startsWith("monthly_deduction")) {
          this.salary[key] = temp_val; // Convert and update the value in the dictionary
          temp_ded += temp_val;
        }
      }
    });

    this.salary["total"] = temp_add - temp_ded;
  }
  save_salary() {
    this.error = ""
    const error_list = utils.getEmptyKeys(this.salary, ["start_at", "end_at", "basic"])
    if (error_list.length > 0) {
      this.fieldType = "salary";
      this.error = utils.generateErrorMessage(error_list);
    }
    else {
      const { start_at, end_at } = this.salary;
      if (new Date(end_at) > new Date(start_at)) {
        // if (utils.checkIfKeyIsEmpty(this.salary, ["start_at", "end_at", "basic"])) {
        //   this.fieldType = "salary";
        //   this.feildsError =
        //     "Please Fill Contract Start at, Contract End at and Basic";
        // } else {
        this.salary["employee"] = this.new_emp_id_exist;
        if (this.new_salary_id_exist != null) {
          HRMService.update_salary(this.new_salary_id_exist, this.salary)
            .then((response) => {
              this.clearSalary(this.id);
              this.new_salary_id_exist = response.data.id;
              this.showModal("Salary Updated, Successfully", "success");
              this.fieldType = "";
              this.feildsError = "Please Fill all Fields";
            })
            .catch((e) => {
              this.showModal("Salary Not Updated", "error");
            });
        } else {
          HRMService.create_salary(this.salary)
            .then((response) => {
              this.clearSalary(this.id);
              this.new_salary_id_exist = response.data.id;
              this.showModal("Salary Created Successfully", "success");
              this.fieldType = "";
              this.feildsError = "Please Fill all Fields";
            })
            .catch((e) => {
              this.showModal("Salary Not Created", "error");
            });
        }
      } else {
        this.error = "Contract End date should be greater than the contract start date.";
        this.fieldType = "salary";
      }
    }
  }
  MapTable() {
    for (const key in this.map_fields) {
      if (
        key.startsWith("monthly_addition") ||
        key.startsWith("monthly_deduction")
      ) {
        const fieldData = this.map_fields[key];
        if (fieldData.status === true || fieldData.status === "true") {
          let newField = {
            key: key,
            label: fieldData.name,
            visible: true,
          };
          this.salaryfields.push(newField);
        }
      }
    }
    this.salaryfields.push(
      { key: "total", visible: true },
      { key: "comments", visible: true },
      { key: "Actions", visible: true }
    );
  }
  get visibleFields() {
    return this.salaryfields.filter((field: any) => field.visible);
  }
  editItem(data: any) {
    this.salary = data;
    this.new_salary_id_exist = data.id;
    this.$root.$emit("bv::toggle::collapse", "sidebar-variant-create");
  }
  clearSalary(id: string) {
    this.get_all(id);
    this.salary = {};
    const salarySidebar = this.$refs.salarySidebar as BvModal | undefined;
    if (salarySidebar) {
      salarySidebar.hide("sidebar-variant-create");
    }
  }
  leave_opt: any = []
  getLeaveTypes() {
    HRMService.getLeaveType().then((response) => {
      // this.leave_opt = response.data.results
      // console.log(this.leave_opt)
    })
  }
  leave_balance: any = []
  leave_result: any = []
  getEmployeeLeave(employee: string, status: string) {
    // console.log(employee)
    HRMService.getEmployeeLeave(employee).then((response) => {
      this.leave_balance = response.data.leave_balances
      this.leave_opt = response.data
      this.leave_result = response.data.result;
    }).catch((err) => {
      if (status === "call") {
        this.showModal(err.response.data.message, "error")
      }
    })
  }
  // @Watch('leave_obj.leave_type_title')
  // onLeaveTypeTitleChange(newVal: string, oldVal: string) {
  //   // Find the selected option and update leave_type_id with its id
  //   const selectedOption = this.leave_opt.find((option: any) => option.title === newVal);
  //   if (selectedOption) {
  //     this.leave_obj.leave_type = selectedOption.id;
  //   } else {
  //     this.leave_obj.leave_type = null; // Handle the case where the option is not found
  //   }
  // }
  getStatusClass(status: string) {
    if (status === 'Not Accepted') {
      return 'status-pill red';
    } else if (status === 'Accepted') {
      return 'status-pill green';
    } else if (status === 'Rejected') {
      return 'status-pill red';
    }
    else if (status === null) {
      return 'status-pill orange';
    }
    else {
      return 'status-pill'; // Default styling if status doesn't match any condition
    }
  }
  discard() {
    if (this.fieldType == 'employee') {
      this.basic_employee = {}
      this.error = ""
    }
  }
  public selected_employees: any = [];

  index = 0;
  search_employee(title: any, index: any) {
    this.index = index;
    if (title.length > 2) {

      UserManagementService.get_Employee_by_Title(title)
        .then((res) => {
          this.selected_employees = res.data;
        })
        .catch((e) => {
          console.log(e);
        });
    }
    if (title.length == 0) {
      this.selected_employees = [];
    }
  }
  get_data(data: any) {
    this.asset_obj.handover_to__title = data.title;
    this.asset_obj.handover_to_id = data.id;

    this.selected_employees = [];
  }
  downloadDocument(url: string) {
    const anchor = document.createElement("a");
    const fileUrl = process.env.VUE_APP_SERVER + url;

    // Check if the URL is valid and the file exists
    fetch(fileUrl)
      .then(response => {
        if (response.status === 200) {
          // The file exists, so we can proceed with the download
          anchor.href = fileUrl;
          anchor.download = "downloaded_file_name"; // You can specify the name of the downloaded file here
          anchor.target = "_blank";

          document.body.appendChild(anchor);
          anchor.click();

          document.body.removeChild(anchor);
          this.showModal("File Downloaded", "success")
        } else {
          this.showModal('File not found or URL is not valid.', "error")
          // Handle the case where the file does not exist
        }
      })
      .catch(error => {
        // Handle other network-related errors
        this.showModal("An error occurred while fetching the file:", "error");
      });
  }
  save_entitlement_policy(data: any) {
    data["employee"] = this.new_emp_id_exist;
    data['employee_title'] = this.basic_employee.title
    data['employee_code'] = this.basic_employee.code

    const selectedPolicyId = this.entilementPolicy
      .filter((item: any) => item.title === data.entitlement_policy_title)
      .map((item: any) => item.id)[0];
    const selectedLeaveType = this.entilementPolicy
      .filter((item: any) => item.title === data.entitlement_policy_title)
      .map((item: any) => item.leave_type)[0];
    data['entitlementpolicy'] = selectedPolicyId
    data['leave_type'] = selectedLeaveType
    if (data.id) {
      HRMService.updateEmployeeLeaveEntitlement(data.id, data)
        .then((res) => {
          this.getEntitlementPolicy(this.new_emp_id_exist);
          this.showModal("Leave Entitlement Policy Updated Successfully", "success");
          this.fieldType = "";
          this.policy_obj = {};
        })
        .catch((e) => {
          console.log(e);
          this.showModal("Leave Entitlement Policy Not Updated", "error");
        });
    } else {
      HRMService.assignEmployeeLeaveEntitlement(data)
        .then((res) => {
          this.getEntitlementPolicy(this.new_emp_id_exist);
          this.showModal("Leave Entitlement Policy Created Successfully", "success");
          this.fieldType = "";
          this.getEmployeeLeaveEntitlment(this.new_emp_id_exist);
          this.policy_obj = {};
        })
        .catch((e) => {
          console.log(e);
          this.showModal("Leave Entitlement Policy Not Created", "error");

        });
    }
  }

  remove_entitlment_policy(data: any) {
    HRMService.removeEmployeeLeaveEntitlement(data.id).then((response) => {
      this.getEmployeeLeaveEntitlment(this.new_emp_id_exist);
      this.showModal("Leave Entitlement Policy Deleted Successfully", "success");
    }).catch((e) => {
      console.log(e);
      this.showModal("Leave Entitlement Policy Not Deleted", "error");
    });
  }

  getEntitlementPolicy(params: any) {
    HRMService.getEntitlementPolicy().then((response) => {
      this.entilementPolicy = response.data
    }).catch((e) => {
      console.log(e);
    });
  }
  chartOptions = {
    chart: {
      height: 400,
      type: 'radialBar',
      toolbar: {
        show: true
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 225,
        hollow: {
          margin: 0,
          size: '70%',
          background: '#fff',
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: 'front',
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.24
          }
        },
        track: {
          background: '#fff',
          strokeWidth: '67%',
          margin: 0, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.35
          }
        },

        dataLabels: {
          show: true,
          name: {
            offsetY: -10,
            show: true,
            color: '#888',
            fontSize: '36px'
          },
          value: {
            formatter: function (val: any) {
              return parseInt(val) + '%';
            },
            color: '#111',
            fontSize: '26px',
            show: true,
          }
        }
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'horizontal',
        shadeIntensity: 0.5,
        gradientToColors: ['#ABE5A1'],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100]
      }
    },
    stroke: {
      lineCap: 'round'
    },
    labels: ['Profile'],
  }
  reset(type: string) {
    this.error = "";
    if (type === 'salary') {
      this.salary = {}
    } else if (type === 'education') {
      this.edu_obj = {}
    } else if (type === 'passport') {
      this.passport_obj = {}
    } else if (type === 'iqama') {
      this.iqama_obj = {}
    } else if (type === 'aramco') {
      this.aramco_obj = {}
    } else if (type === 'visa') {
      this.visa_obj = {}
    } else if (type === 'workpermit') {
      this.workpermit_obj = {}
    } else if (type === 'driving') {
      this.drivinglicense_obj = {}
    } else if (type === 'worklocation') {
      this.project_obj = {}
    } else if (type === 'medical') {
      this.medicalinsurance_obj = {}
    } else if (type === 'asset') {
      this.asset_obj = {}
      this.for_handover = false;
      this.for_handover_edit = false;
    } else if (type === 'leave') {
      this.leave_obj = {}
      this.leave_days = 0
    } else if (type === 'doc') {
      this.doc_obj = {}
    } else if (type === 'dependent') {
      this.dependent_obj = {}
    } else if (type === 'sep') {
      this.per_employee = {}
    }
    else if (type === 'policy') {
      this.policy_obj = {}
    }
  }
  getEmployeeLeaveEntitlment(params: any) {
    HRMService.getEmployeeLeaveEntitlement(params).then((response) => {
      this.item_policy = response.data
    }).catch((err) => { console.log(err) })
  }
  openSidebar() {
    this.$root.$emit("bv::toggle::collapse", "sidebar-variant-separation-verification");
  }
  handleEmpAccountsUpdate(updatedAccounts: any) {
    this.newEmpAccounts = { ...updatedAccounts };
    Object.assign(this.newEmpAccounts, updatedAccounts);
    this.$emit('update:emp_accounts', this.newEmpAccounts);
  }
  handleEmpAssetsUpdate(updatedAssets: any) {
    this.newEmpAssets = [...updatedAssets];
    // this.newEmpAssets.push(...updatedAssets);
    this.$emit('update:emp_assets', this.newEmpAssets);
  }
  validateFile(file: any) {
    // Check if a file is selected
    if (!file) {
      return null;
    }
    // Get the file name
    const fileName = file.name;

    // Define allowed file extensions (PDF and Excel)
    const allowedExtensions = ['.pdf', '.odt', '.xls', '.xlsx', '.ods', '.xlsm,', '.xlsb', '.xltx', '.xltm', '.xlam'];
    // Check if the file has an allowed extension
    const isValid = allowedExtensions.some(extension => fileName.toLowerCase().endsWith(extension));
    return isValid ? true : false;
  }
}
