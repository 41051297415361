
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Sidebar from "@/components/left-sidebar.vue"; // @ is an alias to /src
import Loader from "@/components/loader.vue"; // @ is an alias to /src
import HRMService from "../services/hrm-service";
import PopUp from "@/components/PopUp.vue";

@Component({
    components: {
        Sidebar,
        Loader,
        PopUp,
    },
})
export default class SeparationVerificationSidebar extends Vue {
    @Prop({ type: Number, }) id!: number;
    @Prop({ type: Function, default: () => { } }) separate!: Function;
    @Prop({ type: Object, default: () => { } }) emp_accounts!: any;
    @Prop({ type: Array, default: () => [] }) emp_assets!: any;
    @Prop({ type: Array, default: () => [] }) emp_leave_balance!: any[];

    loading = false;
    selected_accounts: any = {};
    selected_assets: any = [];
    public assets_fields = [
        { key: "asset_title", label: "Asset Title" },
        { key: "assigned_date", label: "Assigned Date" },
        // { key: "returned_date", label: "Return Date" },
        // { key: "document", label: "Document" },
        { key: "comments", label: "Comments" },
    ];
    public leave_fields = [
        { key: "title", label: "Leave Title" },
        { key: "opening_balance", label: "Opening Balance" },
        { key: "availed_balance", label: "Availed Balance" },
        { key: "closing_balance", label: "Available Balance" },
    ];
    errorsArray: any = []
    // For Modal
    modalShow = false;
    modalMessage: string[] = [];
    modalSimpleMessage: string = "";
    modalType = "";
    closeModal() {
        this.modalShow = false;
        this.modalMessage = [];
        this.modalType = "";
        this.modalSimpleMessage = ""
    }
    showModal(message: string, array: string[], type: "success" | "error" | "errorArray",) {
        this.modalShow = true;
        this.modalMessage = array;
        this.modalType = type;
        this.modalSimpleMessage = message
    }
    // modal end
    get_data(id: any) {
        this.loading = true;
        HRMService.getSeparationVerification(id).then((response) => {
            this.selected_accounts = response.data.result;
            this.selected_assets = response.data.employee_assets;
            this.loading = false;
            this.handleData();
        }).catch((e) => {
            this.loading = false;
            console.log(e);
        });
        this.loading = true;
    }
    handleData() {
        const newEmpAccounts = { ...this.emp_accounts };
        Object.assign(newEmpAccounts, this.selected_accounts);
        this.$emit('update:emp_accounts', newEmpAccounts);
        const newEmpAssets = [...this.emp_assets];
        newEmpAssets.push(...this.selected_assets);
        this.$emit('update:emp_assets', newEmpAssets);
    }
    checkAndSeparate() {
        this.errorsArray = []
        const items = this.selected_accounts;
        if (
            items.salary_debit !== 0 ||
            items.salary_credit !== 0 ||
            items.end_debit !== 0 ||
            items.end_credit !== 0 ||
            items.vl_debit !== 0 ||
            items.vl_credit !== 0 ||
            this.selected_assets.length > 0
        ) {
            this.errorsArray.push("The accounts' balances should be zero before proceeding with the separation.")
            if (this.selected_assets.length > 0) {
                this.errorsArray.push("Please ensure all assets are handed over before proceeding with the separation.")
            }
            this.showModal("", this.errorsArray, "errorArray")
        }
        else {
            console.log('else hit')
            this.separate(true);
        }
    }
    @Watch("id")
    onModalShowChanged(newValue: any, oldValue: any) {
        this.get_data(this.id);
    }
}
